import { useMutation } from '@common/bff';
import { useOnLoadingToast, useOnSuccessToast } from '@common/core-components';
import { useIntl } from '@common/i18n';
import { hermesLogger } from '@common/observability';
import { Mutation } from '@types';

import { tripsIntlIds } from '../../intl';
import { EDIT_TRIP } from './update-trip-points.mutation';
import { UseUpdateTripPointsResponse } from './update-trip-points.types';

export function useUpdateTripPoints(): UseUpdateTripPointsResponse {
  const { formatMessage } = useIntl();

  const onLoadingToast = useOnLoadingToast();

  const onUpdatedTripPointsToast = useOnSuccessToast({
    title: formatMessage({
      id: tripsIntlIds.tripDetails.toasts.onTripUpdatedSuccess.title,
    }),
  });

  const [handleUpdateTripPoints, { data, loading, error }] = useMutation<
    Pick<Mutation, 'updateTripPoints'>
  >(EDIT_TRIP, {
    onCompleted: (content) => {
      hermesLogger.info(
        '[UpdateTripPoints] Trip update points sucessfully processed',
        content
      );

      onUpdatedTripPointsToast();
    },

    onError: (error) => {
      hermesLogger.error(
        '[UpdateTripPoints] Error processing Trip update points',
        {
          timestamp: new Date(),
          error: {
            stack: error.stack,
            message: error.message,
            kind: error.name,
          },
        }
      );
    },
  });

  async function updateTripPoints(tripId: string, deliveryIds: string[]) {
    onLoadingToast();

    return handleUpdateTripPoints({
      variables: { tripId, deliveryIds },
    });
  }

  return {
    actions: {
      updateTripPoints,
    },
    data: data?.updateTripPoints,
    loading,
    error,
  };
}
