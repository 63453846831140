import { useQuery } from '@common/bff';
import { hermesLogger } from '@common/observability';
import { Query } from '@types';

import { ALL_TRIPS_STATUSES } from './get-all-trips.constants';
import { GET_ALL_TRIPS } from './get-all-trips.query';
import {
  UseGetAllTripsProps,
  UseGetAllTripsResponse,
} from './get-all-trips.types';

export default function useGetAllTrips({
  hubIds = [],
  statuses = ALL_TRIPS_STATUSES,
  originType,
  limit,
  offset,
  poll = false,
}: UseGetAllTripsProps): UseGetAllTripsResponse {
  const { data, loading, error } = useQuery<Pick<Query, 'tripsPaginated'>>(
    GET_ALL_TRIPS,
    {
      variables: { hubIds, statuses, originType, limit, offset },

      pollInterval: poll ? 4_000 : undefined,

      onCompleted: (content) => {
        hermesLogger.info(
          '[GetAllTrips] Successfully retrieved all Trips',
          content
        );
      },

      onError: (error) => {
        hermesLogger.error('[GetAllTrips] Error getting all Trips', {
          timestamp: new Date(),
          error: {
            stack: error.stack,
            message: error.message,
            kind: error.name,
          },
        });
      },
    }
  );

  return {
    data: data?.tripsPaginated,
    loading,
    error,
  };
}
