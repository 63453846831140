import { useMutation } from '@common/bff';
import { useOnLoadingToast, useOnWarningToast } from '@common/core-components';
import { useIntl } from '@common/i18n';
import { hermesLogger } from '@common/observability';
import { Mutation } from '@types';

import { tripsIntlIds } from '../../intl';
import { UNDO_TRIP } from './undo-trip.mutation';
import { UseUndoTripResponse } from './undo-trip.types';

export type UseUndoTripProps = {
  tripId: string;
};

export default function useUndoTrip({
  tripId,
}: UseUndoTripProps): UseUndoTripResponse {
  const { formatMessage } = useIntl();

  const onLoadingToast = useOnLoadingToast();

  const onTripUndoneToast = useOnWarningToast({
    title: formatMessage(
      {
        id: tripsIntlIds.tripsList.toasts.onWarning.title,
      },
      { tripId }
    ),
    description: formatMessage({
      id: tripsIntlIds.tripsList.toasts.onWarning.description,
    }),
  });

  const [handleUndoTrip, { data, loading, error }] = useMutation<
    Pick<Mutation, 'undoTrip'>
  >(UNDO_TRIP, {
    onCompleted: (content) => {
      hermesLogger.info('[UndoTrip] Trip undo sucessfully processed', content);

      onTripUndoneToast();
    },

    onError: (error) => {
      hermesLogger.error('[UndoTrip] Error processing Trip undo', {
        timestamp: new Date(),
        error: {
          stack: error.stack,
          message: error.message,
          kind: error.name,
        },
      });
    },
  });

  async function undoTrip(tripId: string) {
    onLoadingToast();

    return handleUndoTrip({
      variables: { tripId },
    });
  }

  return {
    actions: {
      undoTrip,
    },
    data: data?.undoTrip,
    loading,
    error,
  };
}
